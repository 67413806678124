













import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class NotificationBody extends Vue {
  @Prop({type: String, default: 'info'}) type
  // todo: position should be horizontal and vertical
  @Prop({type: String, default: 'right'}) position
  @Prop({type: String, default: ''}) text
  @Prop({type: String, default: ''}) icon
  @Prop({type: Number, default: 0}) offset

  show: boolean = false;

  get classNotification() {
    let classList = ['notification-' + this.type, 'notification-' + this.position]
    if (this.icon)
      classList.push(`notification-icon-${this.icon}`)
    return classList.join(' ')
  }

  get style() {
    return {
      top: `${5 + 64 * this.offset}px`
    }
  }

  mounted() {
    this.show = true;
  }
}
