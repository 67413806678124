

































































import {Component, Prop, Vue} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import {compareMembersFn, counterFormatter, firstWord, formatTimestamp, formatMuted, getFirstName} from '@/utils/helpers';
import PopupMenu from '@/components/PopupMenu.vue';
import {tourStore} from "@/store/modules/tour";
import {RolesList} from "@/domain/model/types";

@Component({
  name: 'active-chat-list-item',
  components: {PopupMenu, AvatarWithStatus, GroupAvatar},
  filters: {
    counterFormatter, formatTimestamp
  }
})
export default class ActiveChatListItem extends Vue {
  @Prop({default: false}) groupChat?: boolean;
  @Prop() group?: any;
  @Prop() item?: any;
  @Prop() abbreviation?: string;
  @Prop() userId?: string;
  @Prop({default: false}) online?: boolean;
  @Prop({default: false}) selected?: boolean;

  get hasTitle() {
    return !!this.item.title
  }

  get title() {
    if (this.hasTitle) {
      return this.item.title;
    }
    if(this.isGroup || this.item?.memberIDs.length > 2){
      const custId = this.item.customer?.id
      let titleText = this.item.customer?.name           
      
      //#BAW-983 - customer name + other associates first name      
      const memberUids = Object.keys(this.item?.members || {}).sort();
      memberUids.forEach(uid => {
        const member = this.item.members[uid];        
        if (member.uid != custId) {          
          titleText =  titleText + ', ' + getFirstName(member.name);
        }        
      });
      
      return titleText
    }
    return this.item.customer?.name;
  }

  get subtitle() {
    return this.item.associate?.name
  }

  get isGroup(): boolean {
    return this.item.subtype > 2;
  }

  get casePriority() {
    return this.item.case?.priority || 0;
  }

  get priorities(): any[] {
    const {casePriority} = this
    return [
      {value: 1, title: 'Urgent', skip: casePriority === 1, alert: true},
      {value: 2, title: 'Normal', skip: casePriority === 2},
      {value: 3, title: 'Low', skip: casePriority === 3}
    ];
  }

  get unreadCount(): number {
    return this.item.unread && this.item.unread[this.userId!] || 0;
  }

  messageText(message): string {
    return `${this.userId === message.sender.uid ? 'You' : firstWord(message.sender.name)}: ${message.text}`;
  }

  get urgent() {
    return this.item.case?.priority === 1;
  }

  get priorityClass() {
    switch (this.item.case?.priority) {
      case 1:
        return 'red'
      case 2:
        return 'primary'
      default:
        return 'grey'
    }
  }

  get customerPhotoUrl() {
    return this.item.customer?.photoUrl?.thumbnail;
  }

  get customerId() {
    return this.item.customer?.uid
  }

  get associatePhotoUrl() {
    return this.item.associate?.photoUrl?.thumbnail;
  }

  get associateIds() {
    const hasContacts = !!this.item.associate?.contacts?.length
    return hasContacts
        ? this.item.associate.contacts.map((contact) => contact.associate?.id)
        : [this.item.associate?.uid]
  }

  get imagesUrls() {
    return Object.values(this.item.members)
        .sort(compareMembersFn)
        .filter((item: any) => !!item?.photoUrl)
        .map((item: any) => item.photoUrl.thumbnail);
  }

  get imagesUrlsCnt() {
    let imageUrl = Object.values(this.item.members)
        .sort(compareMembersFn)
        .filter((item: any) => !!item?.photoUrl)
        .map((item: any) => item.photoUrl.thumbnail);

    return imageUrl.length > 0;
  }

  get itemTour() {
    const isTour8 = tourStore.checkTour({tourNumber: 8, role: RolesList.admin})
    if (isTour8 && this.item.id === tourStore.tourTextSessionId) {
      return 'selected'
    } else {
      return false
    }
  }

  onPriorityClicked(priority, group, item) {
    this.$emit('priority-clicked', group, item, priority.value)
  }
};
