




























import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import ActiveChatList from '@/components/ActiveChatList.vue';
import {requestsStore} from '@/store/modules/requests/RequestsStore';
import {profileStore} from '@/store/modules/profile';
import GpNotification from '@/components/custom/Notification.vue';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from 'vue-class-component';
import {tourStore} from "@/store/modules/tour";

@Component({
  name: 'inbox',
  components: {ActiveChatList, GpNotification}
})
export default class Inbox extends mixins(Vue, Notifications) {
  @Prop({default: 'inbox'}) type?: string;
  @Prop({default: 'personal'}) subtype?: string;

  get key() {
    return `${this.type}:${this.subtype}`
  }

  get userId() {
    return profileStore.t2bUser?.id
  }

  get urgent() {
    const items = this.isInbox ? requestsStore.getInboxUrgent : requestsStore.getRejectedUrgent;
    return this.isAll
        ? items.filter((item) => !item.memberIDs.includes(this.userId))
        : items.filter((item) => item.memberIDs.includes(this.userId));
  }

  get normal() {
    const items = this.isInbox ? requestsStore.getInboxNormal : requestsStore.getRejectedNormal;
    return this.isAll
        ? items.filter((item) => !item.memberIDs.includes(this.userId))
        : items.filter((item) => item.memberIDs.includes(this.userId));
  }

  get low() {
    const items = this.isInbox ? requestsStore.getInboxLow : requestsStore.getRejectedLow;
    return this.isAll
        ? items.filter((item) => !item.memberIDs.includes(this.userId))
        : items.filter((item) => item.memberIDs.includes(this.userId));
  }

  get isInbox() {
    return this.type === 'inbox';
  }

  get isAll() {
    return this.subtype === 'all'
  }

  get getInbox() {
    return requestsStore.getInbox
  }

  onRequestSelected(requestId) {
    const routeName = this.isInbox ? 'inbox-request' : 'rejected-request'
    this.$router.push({name: routeName, params: {chatId: requestId}, query: this.$route.query});
  }

  async onRequestRejected() {
    const chatId = await requestsStore.findRequestIdForType({
      type: this.$route.query.type,
      subtype: this.$route.query.subtype
    })
    await this.$router.push({name: 'rejected-request', params: {chatId}, query: { type: 'rejected', subtype: 'personal'}})
  }

  async onRequestUndo() {
    console.log('request UNDO')
  }

  onRequestAccepted(requestId: string) {
    this.$router.push({name: 'active-chat', params: {chatId: requestId}, query: {type: 'active', subtype: 'personal'}});
  }
}
