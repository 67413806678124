import { render, staticRenderFns } from "./ActiveChatListItem.vue?vue&type=template&id=7b3d1d9b&scoped=true&"
import script from "./ActiveChatListItem.vue?vue&type=script&lang=ts&"
export * from "./ActiveChatListItem.vue?vue&type=script&lang=ts&"
import style0 from "./ActiveChatListItem.vue?vue&type=style&index=0&id=7b3d1d9b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b3d1d9b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VRow,VSheet,VSpacer})
