
















































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import SearchView from '@/components/SearchView.vue';
import ActiveChatListItem from '@/components/ActiveChatListItem.vue';
import ListGroupItem from '@/components/ListGroupItem.vue';
import EmptyState from "@/components/EmptyState.vue";
import {Getter} from 'vuex-class';
import {firstWord, twoChars} from '@/utils/helpers';
import {applicationStore} from '@/store/modules/application';
import {requestsStore} from '@/store/modules/requests/RequestsStore';
import {profileStore} from '@/store/modules/profile';
import {tourStore} from "@/store/modules/tour";

@Component({
  name: 'active-chat-list',
  components: {ListGroupItem, ActiveChatListItem, SearchView, EmptyState}
})
export default class ActiveChatList extends Vue {
  @Prop({default: 240}) width?: number | string;
  @Prop({default: 240}) maxWidth?: number | string;
  @Prop({default: 'personal'}) subtype?: string;
  @Prop({default: []}) urgent?: any[];
  @Prop({default: []}) normal?: any[];
  @Prop({default: []}) low?: any[];
  @Prop({default: false}) activeChats!: boolean;
  @Prop({default: false}) showCreateChat!: boolean;

  @Getter selectedTextSession;
  @Getter selectedChatId;

  srcUrgent?: any[] = [];
  srcNormal?: any[] = [];
  srcLow?: any[] = [];
  emptyIcon: string = ''
  emptyTitle: string = ''
  emptyDescription: string = ''
  emptyArrow: boolean = false

  @Watch('urgent') onUrgentChanged(value, oldValue) {
    this.srcUrgent = Object.assign([], value);
  }

  @Watch('normal') onNormalChanged(value, oldValue) {
    this.srcNormal = Object.assign([], value);
  }

  @Watch('low') onLowChanged(value, oldValue) {
    this.srcLow = Object.assign([], value);
  }

  get isAdmin() {
    return applicationStore.isAdmin;
  }

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get selectedRequestId(): string {
    return this.selectedTextSession && this.selectedTextSession.id;
  }

  get style() {
    return {
      width: `${this.width}px`,
      maxWidth: `${this.maxWidth}px`
    };
  }

  get isEmpty() {
    return !(this.srcUrgent?.length || this.srcNormal?.length || this.srcLow?.length)
  }

  get tourNumber() {
    return tourStore.tourNumber
  }

  selected(id): boolean {
    return this.selectedRequestId === id;
  }

  groupColor(priority) {
    switch (priority) {
      case 1:
        return '#FC3844';
      case 2:
        return '#3773F5';
      case 3:
        return '#777770';
      default:
        return '';
    }
  }

  search(term) {
    if (!term) {
      this.srcUrgent = this.urgent;
      this.srcNormal = this.normal;
      this.srcLow = this.low;
      return;
    }
    const searchString = term.toLowerCase();
    this.srcUrgent = this.urgent!!.filter((value => value.from.name.toLowerCase().includes(searchString) || value.to.name.toLowerCase().includes(searchString)));
    this.srcNormal = this.normal!!.filter((value => value.from.name.toLowerCase().includes(searchString) || value.to.name.toLowerCase().includes(searchString)));
    this.srcLow = this.low!!.filter((value => value.from.name.toLowerCase().includes(searchString) || value.to.name.toLowerCase().includes(searchString)));
  }

  onItemSelected(group, item) {
    if (!this.selected(item.id)) {
      this.$emit('request-selected', item.id);
    }
  }

  async onPriorityClicked(value, item, priority) {
    try {
      await requestsStore.changeRequestPriority({id: item.id, priority});
    } catch (error) {
      console.log(error);
    }
  }

  abbreviation(conversation) {
    return twoChars(this.chatItemTitle(conversation));
  }

  chatItemTitle(conversation) {
    if (!conversation) {
      return '';
    }

    //if chat title is given used that title as chat userpic letter 
    if(conversation.title != null) {
      return conversation.title
    }

    switch (conversation.subtype) {
      case 1:
      case 2: {
        const fromId = conversation.from && (conversation.from.uid || conversation.from.id);
        return this.t2bUser!.id === fromId ? conversation.to && conversation.to.name : conversation.from && conversation.from.name;
      }
      case 3:
        {
          if(conversation.customer != null){
            const custId = conversation.customer?.id
            let titleText = conversation.customer?.name || ''  
          
            //#BAW-983 - customer name + other associates first name    
            if(titleText){
              const memberUids = Object.keys(conversation?.members || {}).sort();
              memberUids.forEach(uid => {
                const member = conversation.members[uid];        
                if (member.uid != custId) {          
                  titleText =  titleText + ', ' + firstWord(member.name);
                }        
              });
              return titleText
            }
          }

          return Object.values(conversation.members).map((item: any) => firstWord(item.name)).sort().toString();
        }
      default:
    }
  }

  onlineStatus(conversation): boolean {
    const fromId = conversation.from && (conversation.from.uid || conversation.from.id);
    const member = conversation.members && conversation.members[fromId];
    return member && member.status && member.status.online;
  }

  initEmpty() {
    const name = this.$route.name
    //const type = this.$route.query.type;
    const subtype = this.$route.query.subtype;
    console.log('initEmpty', name, ' ', subtype)

    switch (name) {
      case 'inbox':
        this.emptyIcon = require('../assets/_empty_states_archive.svg')
        if (subtype === 'personal') {
          this.emptyTitle = 'Your inbox is empty'
        } else {
          this.emptyTitle = 'No requests yet'
        }
        this.emptyDescription = 'New requests will appear here'
        this.emptyArrow = false
        break
      case 'rejected':
        this.emptyIcon = require('../assets/_empty_states_archive.svg')
        this.emptyTitle = 'No rejected requests yet'
        this.emptyDescription = 'Rejected requests will appear here'
        this.emptyArrow = false
        break
      case 'active': case 'active-chat':
        this.emptyIcon = require('../assets/_empty_states_chat.svg')
        if (subtype === 'personal') {
          this.emptyTitle = 'No external chats yet'
          this.emptyDescription = 'Start a new case by selecting<br>' +
              'the + at the top and sending<br>' +
              'a new message to a customer'
          this.emptyArrow = true
        } else {
          this.emptyTitle = 'No chats yet'
          this.emptyDescription = `Your team's open external chats<br>will appear here`
          this.emptyArrow = false
        }
        break
    }
  }

  created() {
    this.initEmpty()
    if (this.selectedRequestId) {
      this.$emit('request-selected', this.selectedRequestId);
    }
    this.srcUrgent = Object.assign([], this.urgent);
    this.srcNormal = Object.assign([], this.normal);
    this.srcLow = Object.assign([], this.low);
  }

  updated() {
    this.initEmpty()
  }
};
