
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {VNode} from 'vue';
import NotificationBody from '@/components/custom/NotificationBody.vue';
import {NotificationsSystem} from '@/domain/model/types';
import {notificationsStore} from '@/store/modules/notifications';

@Component({
  name: 'gp-notification',
  components: {NotificationBody}
})
export default class Notification extends Vue {

  @Prop({type: String, default: 'right'}) position;

  timeout: number = 3000;
  appRoot: HTMLElement | null = null;
  child: any | null = null;
  children: any[] = [];

  get notifications() {
    return notificationsStore.notificationsSystem
  }

  get processed() {
    return notificationsStore.processed
  }

  @Watch('notifications')
  onChangNotificationsSystem(after: NotificationsSystem[], _: NotificationsSystem[]) {
    const newNotifications: NotificationsSystem[] = after.filter((item) => this.processed.indexOf(item) === -1)
    newNotifications.forEach((notification) => {
      notificationsStore.addProcessed(notification)
      this.children.forEach((child, indx) => {
        child.$props.offset = indx + 1;
      })
      const notificationBody = Vue.extend(NotificationBody);
      const child = new notificationBody({
        propsData: {
          type: notification.type,
          text: notification.text,
          icon: notification.icon,
          position: notification.position?.horizontal
        }
      }).$mount();
      this.children.unshift(child)
      this.appRoot?.appendChild(child.$el);
      window.setTimeout(() => {
        this.dismissChild();
        notificationsStore.unsetNotification();
      }, this.timeout)
    })
  }

  mounted() {
    this.appRoot = document.getElementById('app');
  }

  dismissChild() {
    const child = this.children.pop();
    if (child) {
      this.appRoot?.removeChild(child.$el)
      child.$destroy()
    }
  }

  render(h): VNode {
    return h('span');
  }

};
